
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/338eadb1c81287ab/wix-events-statics/events-viewer/src/components/schedule/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/work/338eadb1c81287ab/wix-events-statics/events-viewer/src/components/schedule/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://f1ffc0b5efe04e9eb9762cd808722520@sentry.wixpress.com/748',
      id: 'f1ffc0b5efe04e9eb9762cd808722520',
      projectName: 'events-viewer',
      teamName: 'events',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'schedule'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  export default {
    component: UserComponent
  };
